<template>
  <div class="p-grid" style="width: 100%;margin: 0;justify-content: space-between">
    <Breadcrumb :home="breadcrumb.home" :model="breadcrumb.items" style="padding-top:0px;background: none;border: none;" />
    <Button @click="$router.push('/data/detail/dtzz?cm=3&projId='+form.projId)" label="返回" class=" p-button-sm p-button-outlined p-button-secondary"
      style="padding:0px;width:65px;border:1px solid rgba(217, 217, 217, 1);margin-bottom:10px;" />
  </div>
  <div class="card">
    <div class="p-grid" style="width: 60%; margin: 0 auto">
      <h4 class="p-col-12" style="font-weight: bold; margin-left: 8.5vw">
        生长记录
      </h4>
      <div class="p-field p-grid p-col-12">
        <label class="p-col-fixed">农事类型：</label>
        <div class="p-col">
          <span class="myTag p-mr-3" @click="$router.push('/grow/emergence?projId='+$route.query.projId)">出苗记录</span>
          <span class="myTagSuccess p-mr-3">分蘖记录</span>
          <span class="myTag p-mr-3" @click="$router.push('/grow/jointing?projId='+$route.query.projId)">拔节记录</span>
          <span class="myTag p-mr-3" @click="$router.push('/grow/measuring?projId='+$route.query.projId)">植株测量</span>
        </div>
      </div>
      <div class="p-field p-grid p-col-12">
        <label class="p-col-fixed"><i class="p-required">*</i>记录时间：</label>
        <div class="p-col">
          <Calendar date-format="yy-mm-dd" :class="{'p-invalid':(v$.form.opeTime.$invalid && submitted)}" v-model="form.opeTime" />
        </div>
        <span class="err-message" style="padding-left: calc(100px + 1rem);margin-top: .5rem" v-if="v$.form.opeTime.$invalid && submitted">请选择录入时间</span>

      </div>
      <div class="p-field p-grid p-col-12">
        <label class="p-col-fixed">操作人：</label>
        <div class="p-col">
          <InputText v-model="form.opeName" />
        </div>
      </div>
      <div class="p-field p-grid p-col-12">
        <label class="p-col-fixed">农事照片：</label>
        <div class="p-col p-d-flex">
          <div class="upload-div" @click="uploadImg()">
            <img style="width: 30%;height: 30%" src="../../../../../assets/images/add.png">
          </div>
          <div class="p-mr-3" style="position: relative" v-for="(item,index) in form.images">
            <img style="height: 100px;" :src="$minioUrl+item.imgPath">
            <span @click="removeImg(index)"
              style="position: absolute;width: 20px;height: 20px;background: white;border: 1px solid #CED4DA;border-radius: 20px;right: -7px;top: -7px;display: flex;align-items: center;justify-content: center">
              <i class="pi pi-times" style="font-size: 10px"></i>
            </span>
          </div>

        </div>
      </div>
      <div class="p-field p-grid p-col-12">
        <label class="p-col-fixed"><i class="p-required">*</i>分蘖数：</label>
        <div class="p-col p-d-flex">
          <span class="p-mr-3" style="margin-top:3px">
            最高：
            <InputNumber style="vertical-align:middle;position:relative;top:-2px" min="0" :class="{'p-invalid':(v$.form.rateMax.$invalid && submitted)}" class="p-mr-3" v-model="form.rateMax" :minFractionDigits="2" /> 根
          </span>
          <span class="p-mr-3" style="margin-top:3px;margin-left:10px">
            最低：
            <InputNumber style="vertical-align:middle;position:relative;top:-2px" min="0" :class="{'p-invalid':(v$.form.rateMin.$invalid && submitted)}" class="p-mr-3" v-model="form.rateMin" :minFractionDigits="2" /> 根
          </span>
          <span class="p-mr-3" style="margin-top:3px;margin-left:10px">
            平均：
            <InputNumber style="vertical-align:middle;position:relative;top:-2px" min="0" :class="{'p-invalid':(v$.form.rateAvg.$invalid && submitted)}" class="p-mr-3" v-model="form.rateAvg" :minFractionDigits="2" /> 根
          </span>
        </div>
        <span class="err-message" style="padding-left: calc(100px + 1rem);margin-top: .5rem" v-if="(v$.form.rateMax.$invalid||v$.form.rateMin.$invalid||v$.form.rateAvg.$invalid) && submitted">请输入完整的分蘖数</span>
      </div>
      <div class="p-field p-grid p-col-12">
        <label class="p-col-fixed">备注：</label>
        <div class="p-col">
          <Textarea v-model="form.comments" :autoResize="true" rows="4" cols="60" />
        </div>
      </div>
      <div style="margin-left: calc(100px + .5rem)">
        <Button class="p-mr-3" @click="submit">提交</Button>
        <Button class="p-button-outlined p-button-secondary" @click="$router.push('/data/detail/dtzz?cm=3&projId='+form.projId)">
          取消
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default {
  name: "plant",
  data() {
    return {
      breadcrumb: {
        home: { label: "种植项目", to: "#" },
        items: [
          { label: "农事记录与指导", to: "/data/overview" },
          { label: "查看数据", to: "#" },
        ],
      },
      submitted: false,
      form: {
        id: null,
        projId: null,
        opeTime: null,
        opeName: JSON.parse(localStorage.getItem('userinfo')).user.name,
        rateMin: null,
        rateMax: null,
        rateAvg: null,
        images: [],
        comments: null,
      },
      ferDisplay: false,
    };
  },
  setup: () => ({
    v$: useVuelidate(),
  }),
  validations() {
    return {
      form: {
        id: {},
        projId: {},
        opeTime: { required },
        opeName: {},
        rateMin: { required },
        rateMax: { required },
        rateAvg: { required },
        comments: {},
      },
    };
  },
  created() {
    this.form.projId = this.$route.query.projId;
    let id = this.$route.query.id;
    if (id) {
      this.getData(id);
    }
  },
  methods: {
    getData(id) {
      this.$axios
        .get("/recGrowTiller/getOne/", {
          params: { id: id },
        })
        .then((res) => {
          res.data.opeTime = new Date(res.data.opeTime);
          this.form = res.data;
        });
    },
    uploadImg() {
      const _this = this;
      const input = document.createElement("input");
      input.type = "file";
      input.onchange = function () {
        let file = this.files[0];
        let formData = new FormData();
        formData.append("file", file);
        _this.$axios
          .post("/minio/uploadFile", formData, {
            headers: { "Content-Type": "multipart/form-data" },
          })
          .then((res) => {
            _this.form.images.push({
              imgPath: res.data,
            });
          });
      };
      input.click();
    },
    removeImg(index) {
      this.form.images.splice(index, 1);
    },

    submit() {
      this.submitted = true;
      if (this.v$.$invalid) {
        return;
      }

      this.$axios.post("/recGrowTiller/addOrUpdate", this.form).then(() => {
        this.$toast.add({
          severity: "success",
          summary: "新增成功",
          life: 3000,
        });
        this.$router.push("/data/detail/dtzz?cm=3&projId=" + this.form.projId);
      });
    },
  },
};
</script>

<style scoped>
.myTagSuccess {
  height: 20px;
  padding: 0 15px;
  border-radius: 20px;
  background: white;
  border: 1px solid #12a25d;
  color: #12a25d;
  cursor: pointer;
}
.myTag {
  height: 20px;
  padding: 0 15px;
  border-radius: 20px;
  background: white;
  border: 1px solid #79828a;
  cursor: pointer;
}
label {
  margin: 0;
  width: 100px;
  justify-content: flex-end;
}
::v-deep(.p-inputnumber-input) {
  width: 80px;
}

</style>